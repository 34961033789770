.section-area {
  background: #f5f5f5;
}

.section-area.section-style-2 {
  background: #fff;
}
.section-area.section-style-2 .section-item {
  overflow: hidden;
}
.section-area.section-style-2 .section-icon {
  margin-top: 0;
  float: left;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.section-area.section-style-2 .section-content p a:hover {
  color: #34ccff;
}
.section-area.section-style-2 .section-content {
  text-align: left;
  overflow: hidden;
  padding-right: 60px;
}
.section-area.section-style-2 .section-content span {
  font-size: 16px;
  color: #777;
}
.section-area.section-style-2 .section-icon i.fi {
  padding: 0;
  width: 90px;
  height: 90px;
  background: #34ccff;
  border-radius: 50%;
  line-height: 75px;
  text-align: center;
  margin-top: 35px;
  margin-right: 20px;
  color: #fff;
  border: 6px solid #d4f1fa;
}

.section-style-2.section-style-3 .section-wrap {
  padding: 15px 30px 30px;
  background: #f5f5f5;
}

.section-style-2.section-style-3 .section-item-2 {
  padding: 0px;
}

.section-area.section-style-2 .section-icon {
  margin-top: 0;
  float: left;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.section-style-2.section-style-3 {
  padding: 100px 0;
}
.section-area.section-style-2.section-style-3 .section-content {
  padding-right: 0;
}

.section-icon {
  text-align: center;
  margin-top: -60px;
}

.section-area.section-style-2.section-style-3 .section-icon i.fi {
  margin-top: 25px;
}
.section-area.section-style-2 .section-icon i.fi {
  padding: 0;
  width: 90px;
  height: 90px;
  background: #34ccff;
  border-radius: 50%;
  line-height: 75px;
  text-align: center;
  margin-top: 35px;
  margin-right: 20px;
  color: #fff;
  border: 6px solid #d4f1fa;
}
.section-icon i.fi {
  background: #f5f5f5;
  padding: 47px 30px 30px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  transition: all 0.5s;
}

.section-content {
  text-align: center;
  padding: 30px 0 10px;
  transition: all 0.5s;
}
.section-item:hover .section-content {
  background: #34ccff;
  color: #fff;
}
.section-item:hover .section-content p a {
  color: #fff;
}
.section-item:hover .section-icon i.fi {
  background: #34ccff;
  color: #fff;
}
.section-content p {
  font-size: 24px;
  font-weight: 400;
  transition: all 0.5s;
}
.section-content p a {
  color: #333;
  transition: all 0.5s;
}

.section-icon i.fi {
  background: #f5f5f5;
  padding: 47px 30px 30px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  transition: all 0.5s;
}
.col-d {
  padding: 0;
}

.tr-wrap {
  padding-left: 30px;
}
.t-text h2 {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 30px;
}

.t-text p {
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 30px;
}
.tr-s span {
  font-size: 16px;
  display: block;
  padding-bottom: 10px;
  padding-left: 25px;
  position: relative;
}
.tr-s span:before {
  content: "";
  position: absolute;
  left: 0;
  top: 7px;
  width: 10px;
  height: 10px;
  background: #34ccff;
  border-radius: 50%;
}

@media (min-width: 992px) and (max-width: 1197px) {
  .section-style-2.section-style-3 .section-wrap {
    padding: 5px 6px 12px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .section-style-2.section-style-3 .section-wrap {
    padding: 55px 30px 30px;
  }
  .section-style-2.section-style-3 .section-item-2 {
    padding: 0px;
    margin-bottom: 30px;
  }
  .section-area.section-style-2 .section-icon {
    margin-top: -27px;
  }
  .section-area.section-style-2 .section-content {
    padding-right: 46px;
    padding: 0px 0;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .section-area.section-style-2 {
    background: #fff;
    padding: 24px 0 50px;
  }
}
@media (max-width: 575px) {
  .section-area.section-style-2 {
    padding: 30px 0 50px;
  }
}
