li .flag-icon {
  width: 20px;
  height: 15px;
  display: block;
  position: absolute;
  top: 5px;
}
/*! purgecss start ignore */
li .flag-icon.flag-pt {
  background-image: url("../../assets/images/flag-pt.svg");
}

li .flag-icon.flag-en {
  background-image: url("../../assets/images/flag-en.svg");
}
/*! purgecss end ignore */
