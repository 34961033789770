/*------------------------------------------------------------------
[Master Stylesheet]
Theme Name:     Shifter – Transport and Logistics React Template
Version:        1.0.0
Author:         wpoceans
URL:            https://themeforest.net/user/wpoceans
-------------------------------------------------------------------*/

/*---------------------------
  Colors
----------------------------*/
:root {
  --default-text-color: #333;
  --default-p-color: #666;
  --default-h-color: #353535;
  --bg-blue: #263a81;
}

/*---------------------------
  Fonts
----------------------------*/
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700|Roboto:300,400,500,700");
/*--------------------------------------------------------------
#0.1    Theme Reset Style
--------------------------------------------------------------*/

body {
  font-family: "Roboto", sans-serif !important;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  color: #333;
}

@media (max-width: 767px) {
  body {
    font-size: 15px;
  }
}

p {
  color: #666;
  line-height: 30px;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  color: #353535;
}

ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0 !important;
  margin-bottom: 0;
}

a {
  text-decoration: none!important;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

a:hover {
  text-decoration: none !important;
}

img {
  max-width: 100%;
}

button,
a:focus {
  outline: none;
}
.features-padding {
  padding: 100px 0;
}

@media (max-width: 991px) {
  .features-padding {
    padding: 90px 0;
  }
}

@media (max-width: 767px) {
  .features-padding {
    padding: 80px 0;
  }
}

.features-padding {
  padding: 100px 0;
}
.section-padding {
  padding: 100px 0;
}
.ptb-100-70 {
  padding: 100px 0 70px;
}

.fixed-navbar {
  position: relative;
  z-index: 5555;
}
.fixed-navbar.active .header-style-1,
.fixed-navbar.active .header-style-2,
.fixed-navbar.active .header-style-3 {
  position: fixed;
  z-index: 555;
  top: 0;
  left: 0;
  width: 100%;
  background: #142440;
  border: none;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
}
.fixed-navbar.active .header-style-2,
.fixed-navbar.active .header-style-3 {
  background: #231b0e;
}
