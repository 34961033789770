.breadcumb-area {
  min-height: 550px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.breadcumb-area.about-page {
  background: url("../../assets/images/banner-about-2042952230.jpg") no-repeat
    center top / cover;
}
.webp-supported .breadcumb-area.about-page {
  background: url("../../assets/images/webp/banner-about-2042952230.webp")
    no-repeat center top / cover;
}

.breadcumb-area.contact-page {
  background: url("../../assets/images/banner-contacts-2042952395.jpg")
    no-repeat center top / cover;
}
.webp-supported .breadcumb-area.contact-page {
  background: url("../../assets/images/webp/banner-contacts-2042952395.webp")
    no-repeat center top / cover;
}

.breadcumb-area.services-page {
  background: url("../../assets/images/banner-services-2042952362.jpg")
    no-repeat center top / cover;
}
.webp-supported .breadcumb-area.services-page {
  background: url("../../assets/images/webp/banner-services-2042952362.webp")
    no-repeat center top / cover;
}
.breadcumb-area.pricing-page {
  background: url("../../assets/images/banner-pricing-2044257446.jpg") no-repeat center
    top / cover;
}
.webp-supported .breadcumb-area.pricing-page {
  background: url("../../assets/images/webp/banner-pricing-2044257446.webp") no-repeat
    center top / cover;
}

.breadcumb-area.not-found-page {
  background: url("../../assets/images/banner-not-found.jpg") no-repeat center
    top / cover;
}
.webp-supported .breadcumb-area.not-found-page {
  background: url("../../assets/images/webp/banner-not-found.webp") no-repeat
    center top / cover;
}

.breadcumb-area:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #000;
  opacity: 0.6;
}

.breadcumb-wrap h1 {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 13px;
  color: #fff;
  position: relative;
  margin-top: 0;
  line-height: 35px;
}

.breadcumb-wrap ul li {
  display: inline-block;
  padding: 0px 5px;
  color: #fff;
  position: relative;
}

.breadcumb-wrap ul li a {
  color: #fff;
  font-size: 18px;
  transition: all 0.3s;
  position: relative;
}

.breadcumb-wrap ul li a:hover {
  color: #34ccff;
}

.breadcumb-wrap ul li:last-child {
  color: #34ccff;
}

.breadcumb-wrap ul li:after {
  content: "/";
  position: relative;
  left: 7px;
}

.breadcumb-wrap ul li:last-child:after {
  display: none;
}
.about-layout .testimonial-area {
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
}
.features-b {
  border-bottom: none;
}

.breadcumb-wrap {
  padding-top: 95px;
}
